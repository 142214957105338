<template>
  <h3 class="section-title">
    <strong>
      <slot />
    </strong>
    <slot name="button" />
  </h3>
</template>

<script>
export default {
  name: "SectionTitle",
};
</script>

<style lang="scss" scoped>
.section-title {
  font-size: 1.5rem;
  color: #424242;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
</style>
