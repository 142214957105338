<template>
  <div id="createProfileModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
      <form @submit.prevent="createProfile" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <i class="bi bi-caret-right me-1"></i>
            Profil oluştur
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="d-flex align-items-end mb-3">
            <img
              src="@/assets/svg/create_profile.svg"
              alt="Profil oluştur"
              width="120"
              class="me-3"
            />
            <p class="small">
              Kendini en iyi şekilde tanıtmak için profilini hemen oluştur,
              online dünyada iz bırakmaya başla!
            </p>
          </div>
          <fieldset class="form-fieldset">
            <label class="form-label">
              Profil bağlantınız/kısaltmanız
              <strong class="text-danger">*</strong>
            </label>
            <div class="input-group">
              <span class="input-group-text"> wisy.me/ </span>
              <input
                type="text"
                placeholder="ertugrulsencer"
                class="form-control"
                v-model="form_data.extension"
              />
            </div>
          </fieldset>
          <fieldset class="form-fieldset">
            <label class="form-label">
              Biyografi
              <strong class="text-danger">*</strong>
            </label>
            <textarea
              rows="4"
              placeholder="İlgi alanların, deneyimlerin veya seni tanıtan her şey burada!"
              class="form-control"
              v-model="form_data.bio"
            ></textarea>
          </fieldset>
          <fieldset class="form-fieldset">
            <label class="form-label"> Yer/Konum </label>
            <div class="input-group">
              <span class="input-group-text">
                <i class="bi bi-geo-alt"></i>
              </span>
              <input
                type="text"
                placeholder="Türkiye"
                class="form-control"
                v-model="form_data.location"
              />
            </div>
          </fieldset>
          <fieldset class="form-fieldset">
            <label class="form-label"> Telefon numarası </label>
            <div class="input-group">
              <span class="input-group-text">
                <i class="bi bi-telephone"></i>
              </span>
              <input
                type="text"
                placeholder="0 5xx xxx xxxx"
                class="form-control"
                v-model="form_data.phone"
              />
            </div>
          </fieldset>
        </div>
        <div class="modal-footer">
          <div class="form-field-flex mobile-reverse">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Vazgeç
              <i class="bi bi-x-lg ms-1"></i>
            </button>
            <hr class="hr" />
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="create_loading"
            >
              <template v-if="!create_loading">
                Profilimi oluştur
                <i class="bi bi-plus-lg ms-1"></i>
              </template>
              <div class="spinner-border spinner-border-sm" v-else></div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import { mapGetters } from "vuex";

export default {
  name: "CreateProfileModal",
  data: () => ({
    modal: null,
    form_data: {
      extension: "",
      bio: "",
      location: "",
      phone: "",
    },
  }),
  computed: {
    ...mapGetters({ create_loading: "Profile/_create_loading" }),
  },
  methods: {
    createProfile() {
      this.$store
        .dispatch("Profile/CREATE", this.form_data)
        .then((response) => {
          this.modal.hide();
          this.$swal({
            icon: "success",
            title: "Tebrikler",
            text: response.message,
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 3000,
            toast: true,
            position: "top",
          });
        })
        .catch((error) => {
          if (error?.type === "validation")
            return this.$swal({
              icon: "warning",
              title: "Eksik/Hatalı alanlar",
              html: `${error.validation_messages
                .map(
                  (v) =>
                    `<div class="text-warning mb-2"><i class="bi bi-exclamation-circle me-2"></i>${v.message}</div>`
                )
                .join("")}`,
            });
          else if (error?.status === "warning")
            return this.$swal({
              icon: "warning",
              title: "Uyarı",
              text: error.message,
            });
          return this.$swal({
            icon: "error",
            title: "Hata",
            text: error?.message ?? error,
          });
        });
    },
  },
  mounted() {
    this.modal = new bootstrap.Modal("#createProfileModal");
  },
};
</script>

<style lang="scss" scoped></style>
