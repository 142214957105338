import { createStore } from "vuex";
import User from "./modules/User";
import Profile from "./modules/Profile";
import Link from "./modules/Link";
import Letter from "./modules/Letter";
import App from "./modules/App";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    App,
    User,
    Profile,
    Link,
    Letter,
  },
});
