<template>
  <div id="createLinkModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
      <form @submit.prevent="createLink" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <i class="bi bi-caret-right me-1"></i>
            Bağlantı oluştur
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="d-flex align-items-end mb-3">
            <img
              src="@/assets/svg/new_link.svg"
              alt="Bağlantı oluştur"
              width="120"
              class="me-3"
            />
            <p class="small">
              Yeni bir bağlantı ekle, profiline daha fazla değer kat ve herkesin
              sana hızla ulaşmasını sağla!
            </p>
          </div>
          <fieldset class="form-fieldset">
            <label class="form-label">
              Platform
              <strong class="text-danger">*</strong>
            </label>
            <div class="input-group">
              <span class="input-group-text">
                <i class="bi bi-globe2"></i>
              </span>
              <select class="form-control" v-model="form_data.platform">
                <option :value="undefined" disabled>- Lütfen seçin -</option>
                <option
                  v-for="platform_key in Object.keys(platforms)"
                  :value="platform_key"
                  :key="platform_key"
                >
                  {{ platforms[platform_key].name }}
                </option>
              </select>
            </div>
          </fieldset>
          <fieldset class="form-fieldset">
            <label class="form-label"> Link </label>
            <div class="input-group">
              <span class="input-group-text">
                <i class="bi bi-link-45deg"></i>
              </span>
              <input
                type="text"
                placeholder="Bağlantı"
                class="form-control"
                v-model="form_data.url"
              />
            </div>
          </fieldset>
        </div>
        <div class="modal-footer">
          <div class="form-field-flex mobile-reverse">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Vazgeç
              <i class="bi bi-x-lg ms-1"></i>
            </button>
            <hr class="hr" />
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="create_loading"
            >
              <template v-if="!create_loading">
                Bağlantı oluştur
                <i class="bi bi-plus-lg ms-1"></i>
              </template>
              <div class="spinner-border spinner-border-sm" v-else></div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import { mapGetters } from "vuex";

export default {
  name: "CreateLinkModal",
  data: () => ({
    modal: null,
    platforms: {
      website: {
        icon: "bi-globe2",
        name: "Website",
      },
      whatsapp: {
        icon: "bi-whatsapp",
        name: "Whatsapp",
      },
      facebook: {
        icon: "bi-facebook",
        name: "Facebook",
      },
      instagram: {
        icon: "bi-instagram",
        name: "İnstagram",
      },
      x: {
        icon: "bi-twitter-x",
        name: "Twitter / X",
      },
      youtube: {
        icon: "bi-youtube",
        name: "Youtube",
      },
      linkedin: {
        icon: "bi-linkedin",
        name: "Linkedin",
      },
      github: {
        icon: "bi-github",
        name: "Github",
      },
    },
    form_data: {
      platform: undefined,
      url: "",
    },
  }),
  computed: {
    ...mapGetters({ create_loading: "Link/_create_loading" }),
  },
  methods: {
    createLink() {
      this.$store
        .dispatch("Link/CREATE", this.form_data)
        .then((response) => {
          this.form_data = {
            platform: undefined,
            url: "",
          };
          this.$store.dispatch("Link/LIST").catch((error) => {
            this.$swal({
              icon: "error",
              title: "Hata",
              text: error?.message ?? error,
            });
          });
          this.modal.hide();
          this.$swal({
            icon: "success",
            title: "Tebrikler",
            text: response.message,
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 3000,
            toast: true,
            position: "top",
          });
        })
        .catch((error) => {
          if (error?.type === "validation")
            return this.$swal({
              icon: "warning",
              title: "Eksik/Hatalı alanlar",
              html: `${error.validation_messages
                .map(
                  (v) =>
                    `<div class="text-warning mb-2"><i class="bi bi-exclamation-circle me-2"></i>${v.message}</div>`
                )
                .join("")}`,
            });
          else if (error?.status === "warning")
            return this.$swal({
              icon: "warning",
              title: "Uyarı",
              text: error.message,
            });
          return this.$swal({
            icon: "error",
            title: "Hata",
            text: error?.message ?? error,
          });
        });
    },
  },
  mounted() {
    this.modal = new bootstrap.Modal("#createLinkModal");
  },
};
</script>

<style lang="scss" scoped></style>
