<template>
  <div class="home-view">
    <Profile />
    <LinkList v-if="profile" />
  </div>
</template>

<script>
import Profile from "@/components/Home/Profile";
import LinkList from "@/components/Home/LinkList";
import { mapGetters } from "vuex";

export default {
  name: "HomeView",
  components: {
    Profile,
    LinkList,
  },
  computed: {
    ...mapGetters({ profile: "Profile/_profile" }),
  },
};
</script>

<style lang="scss" scoped>
.home-view {
  padding: 2rem 0;
}
</style>
