import axios from "axios";

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    register_loading: false,
    login_loading: false,
    fotget_password_loading: false,
    auth_loading: false,
    edit_loading: false,
    reset_password_loading: false,
  },
  getters: {
    _token(state) {
      return state.token;
    },
    _user(state) {
      return state.user;
    },
    _register_loading(state) {
      return state.register_loading;
    },
    _login_loading(state) {
      return state.login_loading;
    },
    _fotget_password_loading(state) {
      return state.fotget_password_loading;
    },
    _auth_loading(state) {
      return state.auth_loading;
    },
    _edit_loading(state) {
      return state.edit_loading;
    },
    _reset_password_loading(state) {
      return state.reset_password_loading;
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setRegisterLoading(state, payload) {
      state.register_loading = payload;
    },
    setLoginLoading(state, payload) {
      state.login_loading = payload;
    },
    setForgetPasswordLoading(state, payload) {
      state.fotget_password_loading = payload;
    },
    setAuthLoading(state, payload) {
      state.auth_loading = payload;
    },
    setEditLoading(state, payload) {
      state.edit_loading = payload;
    },
    setResetPasswordLoading(state, payload) {
      state.reset_password_loading = payload;
    },
  },
  actions: {
    REGISTER(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setRegisterLoading", true);
        axios
          .post(`/user/register`, {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            password: data.password,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error?.response?.data ?? error);
          })
          .finally(() => {
            context.commit("setRegisterLoading", false);
          });
      });
    },
    LOGIN(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setLoginLoading", true);
        axios
          .post(`/user/login`, {
            email: data.email,
            password: data.password,
          })
          .then((response) => {
            context.commit("setToken", response.data.data.token);
            context.commit("setUser", response.data.data.user);
            localStorage.setItem("ACCESS_TOKEN", response.data.data.token);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit("setToken", null);
            context.commit("setUser", null);
            reject(error?.response?.data ?? error);
          })
          .finally(() => {
            context.commit("setLoginLoading", false);
          });
      });
    },
    FORGET_PASSWORD(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setForgetPasswordLoading", true);
        axios
          .patch(`/user/forget-password`, {
            email: data.email,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error?.response?.data ?? error);
          })
          .finally(() => {
            context.commit("setForgetPasswordLoading", false);
          });
      });
    },
    CHECK_LOCALE_TOKEN(context) {
      if (!localStorage.getItem("ACCESS_TOKEN")) return;
      context.commit("setToken", localStorage.getItem("ACCESS_TOKEN"));
    },
    AUTH(context) {
      return new Promise((resolve, reject) => {
        context.commit("setAuthLoading", true);
        axios
          .get(`/user/auth`, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((response) => {
            context.commit("setUser", response.data.data.user);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit("setToken", null);
            context.commit("setUser", null);
            localStorage.removeItem("ACCESS_TOKEN");
            reject(error?.response?.data ?? error);
          })
          .finally(() => {
            context.commit("setAuthLoading", false);
          });
      });
    },
    LOGOUT(context) {
      return new Promise((resolve) => {
        context.commit("setToken", null);
        context.commit("setUser", null);
        context.commit("Profile/setProfile", null, { root: true });
        localStorage.removeItem("ACCESS_TOKEN");
        resolve();
      });
    },
    EDIT(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setEditLoading", true);
        setTimeout(() => {
          axios
            .put(
              `/user/edit`,
              {
                first_name: data.first_name,
                last_name: data.last_name,
              },
              {
                headers: {
                  Authorization: `Bearer ${context.getters._token}`,
                },
              }
            )
            .then((response) => {
              context.commit("setUser", response.data.data.user);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error?.response?.data ?? error);
            })
            .finally(() => {
              context.commit("setEditLoading", false);
            });
        }, 500);
      });
    },
    RESET_PASSWORD(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setResetPasswordLoading", true);
        setTimeout(() => {
          axios
            .patch(
              `/user/change-password`,
              {
                password: data.new_password,
              },
              {
                headers: {
                  Authorization: `Bearer ${context.getters._token}`,
                },
              }
            )
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error?.response?.data ?? error);
            })
            .finally(() => {
              context.commit("setResetPasswordLoading", false);
            });
        }, 500);
      });
    },
  },
};
