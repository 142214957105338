import axios from "axios";

export default {
  namespaced: true,
  state: {
    profile: null,
    profile_loading: false,
    contact_loading: false,
  },
  getters: {
    _profile(state) {
      return state.profile;
    },
    _profile_loading(state) {
      return state.profile_loading;
    },
    _contact_loading(state) {
      return state.contact_loading;
    },
  },
  mutations: {
    setProfile(state, payload) {
      state.profile = payload;
    },
    setProfileLoading(state, payload) {
      state.profile_loading = payload;
    },
    setContactLoading(state, payload) {
      state.contact_loading = payload;
    },
  },
  actions: {
    INIT(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setProfileLoading", true);
        setTimeout(() => {
          axios
            .get(`/app/${data.profile_ex}`)
            .then((response) => {
              context.commit("setProfile", response.data.data.profile);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error?.response?.data ?? error);
            })
            .finally(() => {
              context.commit("setProfileLoading", false);
            });
        }, 500);
      });
    },
    CONTACT(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setContactLoading", true);
        setTimeout(() => {
          axios
            .post(`/contact/${context.getters._profile.id}`, {
              first_name: data.first_name,
              last_name: data.last_name,
              email: data.email || undefined,
              phone: data.phone || undefined,
              subject: data.subject,
              message: data.message,
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error?.response?.data ?? error);
            })
            .finally(() => {
              context.commit("setContactLoading", false);
            });
        }, 1500);
      });
    },
  },
};
