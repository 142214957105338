import { createApp } from "vue";
import App from "./App";
import router from "./router";
import store from "./store";

import axios from "axios";

import "bootstrap/dist/js/bootstrap.bundle";
import "./assets/scss/bootstrap.scss";
import "./assets/scss/main.scss";

import "bootstrap-icons/font/bootstrap-icons.css";

import "sweetalert2/dist/sweetalert2.min.css";
import VueSweetalert2 from "vue-sweetalert2";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueSweetalert2, {
  confirmButtonText: "Tamam",
  cancelButtonText: "İptal",
  confirmButtonColor: "#90caf9",
});
app.mount("#app");

axios.defaults.baseURL = `${
  process.env.VUE_APP_SERVER ?? "http://localhost:3000"
}/api`;
