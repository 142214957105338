import axios from "axios";

export default {
  namespaced: true,
  state: {
    message_list: [],
    message_detail: null,
    message_list_loading: false,
  },
  getters: {
    _message_list(state) {
      return state.message_list;
    },
    _message_detail(state) {
      return state.message_detail;
    },
    _message_list_loading(state) {
      return state.message_list_loading;
    },
  },
  mutations: {
    setMessageList(state, payload) {
      state.message_list = payload;
    },
    setMessageDetail(state, payload) {
      state.message_detail = payload;
    },
    setMessageListLoading(state, payload) {
      state.message_list_loading = payload;
    },
  },
  actions: {
    LIST(context) {
      return new Promise((resolve, reject) => {
        context.commit("setMessageListLoading", true);
        setTimeout(() => {
          axios
            .get(`/letter/list/${context.rootGetters["Profile/_profile"].id}`, {
              headers: {
                Authorization: `Bearer ${context.rootGetters["User/_token"]}`,
              },
            })
            .then((response) => {
              context.commit("setMessageList", response.data.data.list);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error?.response?.data ?? error);
            })
            .finally(() => {
              context.commit("setMessageListLoading", false);
            });
        }, 500);
      });
    },
  },
};
