<template>
  <aside class="app-sidebar">
    <header class="sidebar-header">
      <img
        src="@/assets/svg/wisy_horizontal.svg"
        alt="Wisyflow logo"
        class="app-logo"
        width="200"
      />
      <button
        class="menu-btn"
        :class="{ active: menu_toggle }"
        @click="menu_toggle = !menu_toggle"
      >
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </button>
    </header>
    <transition name="fade-menu" :appear="true">
      <ul v-show="menu_toggle">
        <li>
          <router-link
            :to="{ name: 'HomeRoute' }"
            exact-active-class="active"
            @click="hideMenu()"
          >
            <i class="bi bi-person-circle"></i>
            Profilim
          </router-link>
        </li>
        <li v-if="profile">
          <router-link
            :to="{ name: 'AppRoute', params: { profileEx: profile.extension } }"
            exact-active-class="active"
            @click="hideMenu()"
          >
            <i class="bi bi-person-vcard"></i>
            Kartviziti Önizle
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'VCardRoute' }"
            exact-active-class="active"
            @click="hideMenu()"
          >
            <i class="bi bi-qr-code"></i>
            QR Kartvizitim
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'EnvelopeRoute' }"
            exact-active-class="active"
            @click="hideMenu()"
          >
            <i class="bi bi-envelope"></i>
            Mesajlar
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'AccountRoute' }"
            exact-active-class="active"
            @click="hideMenu()"
          >
            <i class="bi bi-person"></i>
            Hesap
          </router-link>
        </li>
        <li>
          <hr class="hr" />
        </li>
        <li>
          <button @click="logout" class="btn btn-danger w-100">
            <i class="bi bi-door-closed me-2"></i>
            Güvenli çıkış
          </button>
        </li>
      </ul>
    </transition>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ApSidebar",
  data: () => ({
    menu_toggle: true,
  }),
  computed: {
    ...mapGetters({
      profile: "Profile/_profile",
    }),
  },
  created() {
    if (innerWidth < 420) this.menu_toggle = false;
  },
  methods: {
    hideMenu() {
      if (innerWidth > 420) return;
      this.menu_toggle = false;
    },
    logout() {
      this.menu_toggle = false;
      this.$store.dispatch("User/LOGOUT").then(() => {
        this.$router.push({ name: "LoginRoute" });
        this.$swal({
          icon: "success",
          title: "Güle güle",
          text: "Sizi tekrar görmek için sabırsızlanıyoruz!",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-menu-enter-from,
.fade-menu-leave-to {
  opacity: 0;
  max-height: 0px;
  margin-top: 0px;
}
.fade-menu-enter-active,
.fade-menu-leave-to {
  transition: all 150ms ease-in-out;
}
.fade-menu-enter-to,
.fade-menu-leave-from {
  opacity: 1;
  max-height: 300px;
  margin-top: 1rem;
}

.app-sidebar {
  overflow: hidden;
  margin-top: 2rem;
  background: white;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  box-shadow: 0.25rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  width: 280px;
  @media screen and (max-width: 420px) {
    width: 100%;
  }
  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu-btn {
      width: 1.5rem;
      height: 1.5rem;
      position: relative;
      border: 0;
      background: transparent;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      outline: none;
      display: none;
      .bar {
        width: 100%;
        height: 3px;
        border-radius: 6px;
        position: absolute;
        background: #424242;
        transition: transform 150ms ease-in-out;
        &:first-child {
          top: 3px;
        }
        &:last-child {
          bottom: 3px;
        }
      }
      &.active .bar:first-child {
        transform: translateX(-35%);
      }
      &.active .bar:last-child {
        transform: translateX(35%);
      }
      @media screen and (max-width: 420px) {
        display: flex;
      }
    }
  }
  ul {
    margin-top: 1rem;
    padding-left: 0;
    li {
      list-style: none;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
      a {
        padding: 0.5rem 1rem;
        border-radius: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1rem;
        text-decoration: none;
        color: #424242;
        font-weight: 500;
        transition: background 150ms ease-in-out;
        &::after {
          content: "";
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background: white;
          margin-left: auto;
          opacity: 0;
          transition: opacity 150ms ease-in-out;
        }
        &:hover {
          background: var(--secondary);
        }
        &.active {
          background: var(--primary);
          &::after {
            opacity: 1;
          }
        }
        .bi {
          margin-right: 0.75rem;
        }
      }
    }
  }
}
</style>
