<template>
  <div class="app-wrapper">
    <div class="container">
      <transition name="sidebar">
        <Sidebar v-if="token" />
      </transition>
      <router-view v-slot="{ Component }" class="router-view">
        <transition name="fade" mode="out-in">
          <Component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Sidebar from "@/components/shared/Sidebar";

export default {
  name: "App",
  components: {
    Sidebar,
  },
  computed: {
    ...mapGetters({ token: "User/_token" }),
  },
  created() {
    this.$store.dispatch("User/CHECK_LOCALE_TOKEN");
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.auth && !this.token) {
        return next({ name: "LoginRoute" });
      } else if (to.meta.auth && this.token) {
        return this.$store
          .dispatch("User/AUTH")
          .then(() => next())
          .catch((err) => {
            next({ name: "LoginRoute" });
            this.$swal({
              icon: "error",
              text: err?.message ?? err,
            });
          });
      } else if (
        (to.name === "LoginRoute" ||
          to.name === "ForgetPasswordRoute" ||
          to.name === "RegisterRoute") &&
        this.token
      ) {
        next(from);
      } else {
        next();
      }
    });
  },
};
</script>

<style lang="scss">
.app-wrapper .container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2.5rem;
  .router-view {
    flex: 1;
  }
  @media screen and (max-width: 420px) {
    flex-wrap: wrap;
    gap: 0;
  }
}

.sidebar-enter-from,
.sidebar-leave-to {
  opacity: 0;
  margin-left: -280px;
}
.sidebar-enter-active,
.sidebar-leave-to {
  transition: all 150ms ease-in-out;
}
.sidebar-enter-to,
.sidebar-leave-from {
  opacity: 1;
  margin-left: 0px;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-to {
  transition: all 150ms ease-in-out;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
