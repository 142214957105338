<template>
  <section class="link-list-section">
    <section-title>
      <i class="bi bi-link-45deg me-1"></i>
      Bağlantılarınız
      <template v-slot:button v-if="link_list?.length && !link_list_loading">
        <button
          class="btn btn-primary ms-auto"
          data-bs-toggle="modal"
          data-bs-target="#createLinkModal"
        >
          Yeni
          <i class="bi bi-plus-circle ms-1"></i>
        </button>
      </template>
    </section-title>
    <transition name="fade" mode="out-in">
      <div class="loader-center" v-if="link_list_loading">
        <div class="spinner-border"></div>
      </div>
      <div
        class="not-found"
        v-else-if="!link_list.length && !link_list_loading"
      >
        <img
          src="@/assets/svg/link_not_found.svg"
          alt="Bağlantı bulunamadı"
          width="150"
        />
        <p>
          Bağlantıların şu an boş! Sosyal medya hesaplarını ve iletişim
          bilgilerini buradan ekleyerek profilini zenginleştir.
        </p>
        <button
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#createLinkModal"
        >
          Bağlantı ekle
          <i class="bi bi-plus ms-1"></i>
        </button>
      </div>
      <div class="link-list" v-else>
        <ul class="list-group">
          <li class="list-group-item" v-for="link in link_list" :key="link.id">
            <div class="platform">
              <i class="bi me-2" :class="platforms[link.platform].icon"></i>
              <strong>
                {{ platforms[link.platform].name }}
                <em class="small">({{ link.view }})*</em>
              </strong>
              <button
                class="btn btn-sm btn-secondary ms-auto me-3"
                data-bs-toggle="modal"
                data-bs-target="#editLinkModal"
                @click="editLink(link)"
              >
                <i class="bi bi-pencil"></i>
              </button>
              <button class="btn btn-sm btn-danger" @click="deleteLink(link)">
                <i class="bi bi-trash3" v-if="!link.delete_loading"></i>
                <span class="spinner-border spinner-border-sm" v-else></span>
              </button>
            </div>
          </li>
        </ul>
        <p class="small">
          * Profilinizdeki bağlantıya tıklayan ziyaretçilerin toplamı.
        </p>
      </div>
    </transition>
    <teleport to="body">
      <EditLinkModal />
      <CreateLinkModal />
    </teleport>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import SectionTitle from "@/components/shared/SectionTitle";
import CreateLinkModal from "@/components/Home/CreateLinkModal";
import EditLinkModal from "@/components/Home/EditLinkModal";

export default {
  name: "ProfileLinkList",
  components: {
    SectionTitle,
    CreateLinkModal,
    EditLinkModal,
  },
  data: () => ({
    platforms: {
      website: {
        icon: "bi-globe2",
        name: "Website",
      },
      whatsapp: {
        icon: "bi-whatsapp",
        name: "Whatsapp",
      },
      facebook: {
        icon: "bi-facebook",
        name: "Facebook",
      },
      instagram: {
        icon: "bi-instagram",
        name: "İnstagram",
      },
      x: {
        icon: "bi-twitter-x",
        name: "Twitter / X",
      },
      youtube: {
        icon: "bi-youtube",
        name: "Youtube",
      },
      linkedin: {
        icon: "bi-linkedin",
        name: "Linkedin",
      },
      github: {
        icon: "bi-github",
        name: "Github",
      },
    },
  }),
  computed: {
    ...mapGetters({
      link_list_loading: "Link/_link_list_loading",
      link_list: "Link/_link_list",
      edit_detail: "Link/_edit_detail",
    }),
  },
  methods: {
    editLink(link) {
      this.$store.commit("Link/setEditDetail", link);
    },
    deleteLink(link) {
      this.$swal({
        icon: "question",
        title: "Emin misiniz?",
        text: "Bu bağlantıyı silmek istediğinize emin misiniz?",
        showCancelButton: true,
        confirmButtonText: "Sil",
      }).then((result) => {
        if (!result.isConfirmed) return;
        link.delete_loading = true;
        setTimeout(() => {
          this.$store
            .dispatch("Link/DELETE", link)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Başarılı",
                text: response.message,
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 3000,
                toast: true,
                position: "top",
              });
              this.$store.dispatch("Link/LIST").catch((error) => {
                this.$swal({
                  icon: "error",
                  title: "Hata",
                  text: error?.message ?? error,
                });
              });
            })
            .catch((error) => {
              this.$swal({
                icon: "error",
                title: "Hata",
                text: error?.message ?? error,
              });
            })
            .finally(() => {
              link.delete_loading = false;
            });
        }, 500);
      });
    },
  },
  created() {
    this.$store.dispatch("Link/LIST").catch((error) => {
      this.$swal({
        icon: "error",
        title: "Hata",
        text: error?.message ?? error,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.link-list-section {
  background: white;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  margin-top: 2rem;
  box-shadow: 0.25rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  .list-group {
    margin-bottom: 0.75rem;
    .list-group-item {
      .platform {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0.35rem;
        > .bi {
          color: #424242;
        }
      }
    }
  }
}
</style>
