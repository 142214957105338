import axios from "axios";

export default {
  namespaced: true,
  state: {
    profile: null,
    create_loading: false,
    profile_detail_loading: false,
    profile_edit_loading: false,
    upload_photo_loading: false,
    remove_photo_loading: false,
  },
  getters: {
    _profile(state) {
      return state.profile;
    },
    _create_loading(state) {
      return state.create_loading;
    },
    _profile_detail_loading(state) {
      return state.profile_detail_loading;
    },
    _profile_edit_loading(state) {
      return state.profile_edit_loading;
    },
    _upload_photo_loading(state) {
      return state.upload_photo_loading;
    },
    _remove_photo_loading(state) {
      return state.remove_photo_loading;
    },
  },
  mutations: {
    setProfile(state, payload) {
      state.profile = payload;
    },
    setCreateLoading(state, payload) {
      state.create_loading = payload;
    },
    setProfileDetailLoading(state, payload) {
      state.profile_detail_loading = payload;
    },
    setProfileEditLoading(state, payload) {
      state.profile_edit_loading = payload;
    },
    setUploadPhotoLoading(state, payload) {
      state.upload_photo_loading = payload;
    },
    setRemovePhotoLoading(state, payload) {
      state.remove_photo_loading = payload;
    },
  },
  actions: {
    CREATE(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setCreateLoading", true);
        setTimeout(() => {
          axios
            .post(
              `/profile/create`,
              {
                extension: data.extension,
                cover_image: 1,
                bio: data.bio,
                location: data.location ?? null,
                phone: data.phone ?? null,
              },
              {
                headers: {
                  Authorization: `Bearer ${context.rootGetters["User/_token"]}`,
                },
              }
            )
            .then((response) => {
              context.commit("setProfile", response.data.data.new_profile);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error?.response?.data ?? error);
            })
            .finally(() => {
              context.commit("setCreateLoading", false);
            });
        }, 500);
      });
    },
    UPLOAD_PHOTO(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setUploadPhotoLoading", true);
        setTimeout(() => {
          axios
            .put(`/profile/upload-photo`, data, {
              headers: {
                Authorization: `Bearer ${context.rootGetters["User/_token"]}`,
              },
            })
            .then((response) => {
              context.commit("setProfile", response.data.data.profile);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.response?.data ?? error);
            })
            .finally(() => {
              context.commit("setUploadPhotoLoading", false);
            });
        }, 500);
      });
    },
    REMOVE_PHOTO(context) {
      return new Promise((resolve, reject) => {
        context.commit("setRemovePhotoLoading", true);
        setTimeout(() => {
          axios
            .delete(`/profile/remove-photo`, {
              headers: {
                Authorization: `Bearer ${context.rootGetters["User/_token"]}`,
              },
            })
            .then((response) => {
              context.commit("setProfile", response.data.data.profile);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.response?.data ?? error);
            })
            .finally(() => {
              context.commit("setRemovePhotoLoading", false);
            });
        }, 500);
      });
    },
    GET_DETAIL(context) {
      return new Promise((resolve, reject) => {
        context.commit("setProfileDetailLoading", true);
        axios
          .get(`/profile/detail`, {
            headers: {
              Authorization: `Bearer ${context.rootGetters["User/_token"]}`,
            },
          })
          .then((response) => {
            context.commit("setProfile", response.data.data.profile);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error?.response?.data ?? error);
          })
          .finally(() => {
            context.commit("setProfileDetailLoading", false);
          });
      });
    },
    EDIT(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setProfileEditLoading", true);
        setTimeout(() => {
          axios
            .put(
              `/profile/edit`,
              {
                extension: data.extension,
                cover_image: data.cover_image,
                bio: data.bio,
                location: data.location,
                phone: data.phone,
              },
              {
                headers: {
                  Authorization: `Bearer ${context.rootGetters["User/_token"]}`,
                },
              }
            )
            .then((response) => {
              context.commit("setProfile", response.data.data.profile);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error?.response?.data ?? error);
            })
            .finally(() => {
              context.commit("setProfileEditLoading", false);
            });
        }, 250);
      });
    },
  },
};
