<template>
  <section class="profile-section">
    <section-title>
      <i class="bi bi-person-circle me-2"></i>
      Profiliniz
    </section-title>
    <transition name="fade" mode="out-in">
      <div class="loader-center" v-if="profile_loading">
        <div class="spinner-border"></div>
      </div>
      <div class="not-found" v-else-if="!profile && !profile_loading">
        <img
          src="@/assets/svg/not_found.svg"
          alt="Profil bulunamadı"
          width="150"
        />
        <p>
          Henüz profilini oluşturmadın mı? Kendi tarzını yansıtan bir profil
          oluştur ve seni keşfetmelerini sağla!
        </p>
        <button
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#createProfileModal"
        >
          Hemen oluştur
          <i class="bi bi-plus ms-1"></i>
        </button>
        <teleport to="body">
          <CreateProfileModal />
        </teleport>
      </div>
      <form @submit.prevent="editProfile" v-else>
        <div class="form-flex">
          <div class="flex-item">
            <fieldset class="form-fieldset">
              <label class="form-label">Profil resmi</label>
              <div class="pp-flex">
                <img
                  :src="`${SERVER_URL}/pp/${profile.photo}`"
                  alt="Profile photo"
                  width="70"
                  height="70"
                  class="me-2"
                  v-if="profile.photo"
                />
                <img
                  src="@/assets/svg/default_pp.svg"
                  alt="Profile photo"
                  width="70"
                  height="70"
                  class="me-2"
                  v-else
                />
                <div class="buttons">
                  <button
                    type="button"
                    class="btn btn-sm btn-secondary ms-3"
                    data-bs-toggle="modal"
                    data-bs-target="#uploadProfilePhotoModal"
                  >
                    <template v-if="profile.photo">
                      Profil resmimi değiştir
                      <i class="bi bi-arrow-repeat ms-1"></i>
                    </template>
                    <template v-else>
                      Profil resmi yükle
                      <i class="bi bi-cloud-plus"></i>
                    </template>
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-danger ms-3"
                    v-if="profile.photo"
                    :disabled="remove_photo_loading"
                    @click="removePhoto"
                  >
                    <template v-if="!remove_photo_loading">
                      Profil resmimi kaldır
                      <i class="bi bi-trash3 ms-1"></i>
                    </template>
                    <span
                      class="spinner-border spinner-border-sm"
                      v-else
                    ></span>
                  </button>
                </div>
              </div>
            </fieldset>
            <fieldset class="form-fieldset">
              <label class="form-label">Biyografiniz</label>
              <textarea
                rows="3"
                class="form-control"
                v-model="profile.bio"
              ></textarea>
            </fieldset>
          </div>
          <div class="flex-item">
            <fieldset class="form-fieldset">
              <label class="form-label">Profil uzantınız</label>
              <div class="input-group">
                <span class="input-group-text"> wisy.me/</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="profile.extension"
                />
              </div>
            </fieldset>
            <fieldset class="form-fieldset">
              <label class="form-label">Yer/Konum</label>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="bi bi-geo-alt"></i>
                </span>
                <input
                  class="form-control"
                  placeholder="Türkiye"
                  v-model="profile.location"
                />
              </div>
            </fieldset>
            <fieldset class="form-fieldset">
              <label class="form-label">Telefon numarası</label>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="bi bi-telephone"></i>
                </span>
                <input
                  class="form-control"
                  placeholder="05xx xxx xxxx"
                  v-model="profile.phone"
                />
              </div>
            </fieldset>
          </div>
        </div>
        <div class="form-field-flex">
          <button
            type="submit"
            class="btn btn-primary flex-auto"
            :disabled="edit_loading"
          >
            <template v-if="!edit_loading">
              Kaydet
              <i class="bi bi-cloud-arrow-up ms-1"></i>
            </template>
            <div class="spinner-border spinner-border-sm" v-else></div>
          </button>
        </div>
      </form>
    </transition>
    <teleport to="body">
      <UploadProfilePhotoModal />
    </teleport>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import SectionTitle from "@/components/shared/SectionTitle";
import CreateProfileModal from "@/components/Home/CreateProfileModal";
import UploadProfilePhotoModal from "@/components/Home/UploadProfilePhotoModal";

export default {
  name: "ProfileCard",
  data: () => ({
    SERVER_URL: "",
  }),
  components: {
    SectionTitle,
    CreateProfileModal,
    UploadProfilePhotoModal,
  },
  computed: {
    ...mapGetters({
      profile: "Profile/_profile",
      profile_loading: "Profile/_profile_detail_loading",
      edit_loading: "Profile/_profile_edit_loading",
      remove_photo_loading: "Profile/_remove_photo_loading",
    }),
  },
  methods: {
    removePhoto() {
      this.$swal({
        icon: "question",
        title: "Emin misiniz?",
        text: "Profil resminizi kaldırmak istediğinize emin misiniz?",
        showCancelButton: true,
        confirmButtonText: "Eminim",
      }).then((res) => {
        if (!res.isConfirmed) return;
        this.$store
          .dispatch("Profile/REMOVE_PHOTO")
          .then((response) => {
            this.$swal({
              icon: "success",
              title: "Tebrikler",
              text: response.message,
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 3000,
              toast: true,
              position: "top",
            });
          })
          .catch((error) => {
            if (error.status === "warning")
              return this.$swal({
                icon: "warning",
                title: "Dikkat",
                text: error.message,
              });
            this.$swal({
              icon: "error",
              title: "Hata",
              text: error?.message ?? error,
            });
          });
      });
    },
    editProfile() {
      this.$store
        .dispatch("Profile/EDIT", this.profile)
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Tebrikler",
            text: response.message,
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 3000,
            toast: true,
            position: "top",
          });
        })
        .catch((error) => {
          if (error?.type === "validation")
            return this.$swal({
              icon: "warning",
              title: "Eksik/Hatalı alanlar",
              html: `${error.validation_messages
                .map(
                  (v) =>
                    `<div class="text-warning mb-2"><i class="bi bi-exclamation-circle me-2"></i>${v.message}</div>`
                )
                .join("")}`,
            });
          if (error.status === "warning")
            return this.$swal({
              icon: "warning",
              title: "Dikkat",
              text: error.message,
            });
          this.$swal({
            icon: "error",
            title: "Hata",
            text: error?.message ?? error,
          });
        });
    },
  },
  created() {
    this.SERVER_URL = process.env.VUE_APP_SERVER;
    this.$store.dispatch("Profile/GET_DETAIL").catch((error) => {
      if (error.status === "warning") return;
      this.$swal({
        icon: "error",
        title: "Hata",
        text: error?.message ?? error,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.profile-section {
  background: white;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  box-shadow: 0.25rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  .form-flex {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    @media screen and (max-width: 420px) {
      flex-wrap: wrap;
      gap: 0;
    }
    .flex-item {
      flex: 100%;
      .pp-flex {
        display: flex;
        align-items: center;
        img {
          object-fit: contain;
          border-radius: 50%;
          border: 1px solid #f8f8f8;
        }
        .buttons {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .btn:not(:last-child) {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
