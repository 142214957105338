import axios from "axios";

export default {
  namespaced: true,
  state: {
    link_list: null,
    edit_detail: null,
    link_list_loading: false,
    create_loading: false,
    edit_loading: false,
  },
  getters: {
    _link_list(state) {
      return state.link_list;
    },
    _edit_detail(state) {
      return state.edit_detail;
    },
    _link_list_loading(state) {
      return state.link_list_loading;
    },
    _create_loading(state) {
      return state.create_loading;
    },
    _edit_loading(state) {
      return state.edit_loading;
    },
  },
  mutations: {
    setLinkList(state, payload) {
      state.link_list = payload;
    },
    setEditDetail(state, payload) {
      state.edit_detail = payload;
    },
    setLinkListLoading(state, payload) {
      state.link_list_loading = payload;
    },
    setCreateLoading(state, payload) {
      state.create_loading = payload;
    },
    setEditLoading(state, payload) {
      state.edit_loading = payload;
    },
  },
  actions: {
    CREATE(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setCreateLoading", true);
        setTimeout(() => {
          axios
            .post(
              `/link/create/${context.rootGetters["Profile/_profile"].id}`,
              {
                platform: data.platform,
                url: data.url,
              },
              {
                headers: {
                  Authorization: `Bearer ${context.rootGetters["User/_token"]}`,
                },
              }
            )
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error?.response?.data ?? error);
            })
            .finally(() => {
              context.commit("setCreateLoading", false);
            });
        }, 500);
      });
    },
    LIST(context) {
      return new Promise((resolve, reject) => {
        context.commit("setLinkListLoading", true);
        axios
          .get(`/link/list/${context.rootGetters["Profile/_profile"].id}`, {
            headers: {
              Authorization: `Bearer ${context.rootGetters["User/_token"]}`,
            },
          })
          .then((response) => {
            context.commit("setLinkList", response.data.data.list);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error?.response?.data ?? error);
          })
          .finally(() => {
            context.commit("setLinkListLoading", false);
          });
      });
    },
    EDIT(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setEditLoading", true);
        setTimeout(() => {
          axios
            .put(
              `/link/edit/${data.id}`,
              {
                platform: data.platform,
                url: data.url,
              },
              {
                headers: {
                  Authorization: `Bearer ${context.rootGetters["User/_token"]}`,
                },
              }
            )
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error?.response?.data ?? error);
            })
            .finally(() => {
              context.commit("setEditLoading", false);
            });
        }, 250);
      });
    },
    DELETE(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/link/delete/${data.id}`, {
            headers: {
              Authorization: `Bearer ${context.rootGetters["User/_token"]}`,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error?.response?.data ?? error);
          });
      });
    },
  },
};
