<template>
  <div id="uploadProfilePhotoModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
      <form
        id="uploadProfilePhotoForm"
        class="modal-content"
        @submit.prevent="uploadProfilePhoto"
      >
        <div class="modal-header">
          <h5 class="modal-title">
            <i class="bi bi-caret-right me-1"></i>
            Profil resmi yükle
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="d-flex align-items-end mb-3">
            <img
              src="@/assets/svg/profile_photo.svg"
              alt="Profil resmi yükle"
              width="120"
              class="me-3"
            />
            <p class="small">
              Profilini daha çekici hale getir! Bir profil resmi yükleyerek
              kendini daha iyi ifade et.
            </p>
          </div>
          <fieldset class="form-fieldset">
            <label class="form-label">
              Profil resminiz
              <strong class="text-danger">*</strong>
            </label>
            <input
              type="file"
              placeholder="ertugrulsencer"
              class="form-control"
              accept=".png,.jpg,.jpeg"
              name="photo"
            />
          </fieldset>
        </div>
        <div class="modal-footer">
          <div class="form-field-flex mobile-reverse">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Vazgeç
              <i class="bi bi-x-lg ms-1"></i>
            </button>
            <hr class="hr" />
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="upload_photo_loading"
            >
              <template v-if="!upload_photo_loading">
                Profil resmini güncelle
                <i class="bi bi-arrow-repeat ms-1"></i>
              </template>
              <div class="spinner-border spinner-border-sm" v-else></div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import { mapGetters } from "vuex";

export default {
  name: "UploadProfileModal",
  data: () => ({
    modal: null,
  }),
  computed: {
    ...mapGetters({ upload_photo_loading: "Profile/_upload_photo_loading" }),
  },
  methods: {
    uploadProfilePhoto() {
      const form_data = new FormData(
        document.querySelector("#uploadProfilePhotoForm")
      );
      this.$store
        .dispatch("Profile/UPLOAD_PHOTO", form_data)
        .then((response) => {
          this.modal.hide();
          this.$swal({
            icon: "success",
            title: "Tebrikler",
            text: response.message,
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 3000,
            toast: true,
            position: "top",
          });
        })
        .catch((error) => {
          if (error?.type === "validation")
            return this.$swal({
              icon: "warning",
              title: "Eksik/Hatalı alanlar",
              html: `${error.validation_messages
                .map(
                  (v) =>
                    `<div class="text-warning mb-2"><i class="bi bi-exclamation-circle me-2"></i>${v.message}</div>`
                )
                .join("")}`,
            });
          else if (error?.status === "warning")
            return this.$swal({
              icon: "warning",
              title: "Uyarı",
              text: error.message,
            });
          return this.$swal({
            icon: "error",
            title: "Hata",
            text: error?.message ?? error,
          });
        });
    },
  },
  mounted() {
    this.modal = new bootstrap.Modal("#uploadProfilePhotoModal");
  },
};
</script>

<style lang="scss" scoped></style>
